<template>
  <div>
    <div class="content">
      <!-- <div class="contentL">
        <div class="contentLtitle">变配电站</div>
      
        <div class="tree-container">
          <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData" :check-on-click-node="false
            " :props="defaultProps" node-key="id" @node-click="handleCheck"></el-tree>
        </div>

      </div> -->
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">实时碳排</div>
        </div>
        <div class="searchContent">
          <div class="titleEcharts">碳排实时数据分析统计情况</div>
        </div>
        <div class="searchContent">
          <div id="myEcharts" class="mycharts">
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import  {emissionsCarbonApi}  from '@/api/emissionsCarbon.js'


import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
     stationNumber:'',
     currentDateStr:'',
     

    };
  },
  // components:{demo},
  created(){
    this.$store.commit('increment', '碳排统计')
    this.$store.commit('selectChild', '实时碳排')
    this.$store.commit('selectChildren', '')
   
  },

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    // this.getList()

    this.drawEnergy()

  },
  computed: {

  },


  methods: {
   
    drawEnergy() {
      this.stationNumber=sessionStorage.getItem('stationNumber')
    console.log( this.stationNumber);
    // this.getCurrentDate()
    var date = new Date();
      date.setDate(date.getDate() );
      var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    console.log(this.currentDateStr);
    this.currentDateStr=yesterday
    let obj={
      stationNumber:this.stationNumber,
      time:this.currentDateStr
    }
      emissionsCarbonApi.realTimeBon(obj).then(res=>{
        let dataX=[]
        let dataSer=[]
        res.data.forEach(item=>{
          dataSer.push(item.tanPait)
          dataX.push(item.ts.slice(11,16))
          // let time =item.ts.slice(11,16)
          // console.log(time);
        })
        
        var myEcharts = echarts.init(document.getElementById("myEcharts"));
  
       
        var option = {
          title: {
            text: "单位:tCO2e",
            left: 10,
            textStyle:{
              color:"#aed6ff",
              fontSize:16
            }
          },
         
          legend: {
            data: ["实时碳排"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          toolbox: {
            show:false,
            feature: {
              dataZoom: {
                yAxisIndex: false
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            bottom: 90,
            left:'4%',
            right:'3%'
          },
          dataZoom: [
            {
              type: 'inside'
            },
            {
              type: 'slider'
            }
          ],
          xAxis: {
            data: dataX,
            silent: false,
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
             // interval: 30, 
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            splitArea: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            axisLabel: {
              color: '#aed6ff' // 
          }
          },
          legend: {
            data: ["实时碳排"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          backgroundColor: '#071635',
          series: [
            {
              name: "实时碳排",
              type: 'line',
              data: dataSer,
              // Set `large` for large data amount
              large: true,
              lineStyle: {
                  color: '#D5A434' 
                },
                itemStyle: {
                normal: {
                  color: '#D5A434' // 设置节点颜色为蓝色
                }
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(224, 171, 52, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(224, 171, 52, 0)'
                  }
                ])
              },
  
            }
          ]
        };
      
  
        myEcharts.setOption(option);
      })
    },
    getCurrentDate() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var day = currentDate.getDate();
   this.currentDateStr = year + "-" + month + "-" + day;
  }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/rightBG.png');
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;

}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  background-color: #002C6C;
}

.day1 {
  background-color: #002C6C;

}

.mycharts {
  width: 100%;
  height: 800px;
}

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}
::v-deep.el-tree{
  width: 300px;
} 

</style>